
export default {
	name: 'projectList',
	data() {
		return {
			list: '',
			search: this.$store.state.result,
			total: 0,
			pageSize: 0,
			currentPage: 1
		}
	},

	created() {
		this.token = this.getCookie('token')
		this.getOfficeList()
	},
	methods: {
		getOfficeList() {
			var _this = this;
			_this.$http.post('/API/PLAT/smoPageB', {
				name: _this.search,
				page: _this.currentPage
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.list = response.data.data.objArray;
					_this.total = response.data.data.total;
					_this.pageSize = response.data.data.pageSize;
					_this.currentPage = response.data.data.currentPage;
				}
			})
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage
			this.getOfficeList()
		},
		searchBtn() {
			var _this = this;
			_this.$http.post('/API/PLAT/smoPageB', {
				name: _this.search,
				page: 1
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					console.log(response)
					_this.list = response.data.data.objArray;
					_this.total = response.data.data.total;
					_this.pageSize = response.data.data.pageSize;
					_this.currentPage = response.data.data.currentPage;
				}
			})
		},
		toCrcMena() {
			this.$router.push('/smoStatistics')
		},
		toPar(id) {
			this.$router.push({ name: 'crcParticulars', params: { id: id } });
			this.$store.commit('getId', id)
		},
		toProject(id) {
			this.$router.push({ name: 'projectParticulars', params: { id: id } })
		},
		export2Excel() {
			require.ensure([], () => {
				const { export_json_to_excel } = require('../../vendor/Export2Excel');
				const tHeader = ['项目简称', 'CRC', '年龄', '学历', '专业', '介入日期', '退出日期'];
				const filterVal = ['shortName', 'crcName', 'years', 'major', 'andTime', 'outTime'];
				const list = this.list;
				const data = this.formatJson(filterVal, list);
				export_json_to_excel(tHeader, data, '项目人员详情列表');
			})
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map(v => filterVal.map(j => v[j]))
		}
	}
}
